import axios from '@/shared/axios';
import { BaseResponse } from '@/types/web.interface';

import type { UserInfo } from '@/types/home';

export async function editEmail(locale: string, params?: any) {
  const res = await axios.get<BaseResponse<any>>('/edit/email', {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function editPassword(locale: string, params?: any) {
  const res = await axios.get<BaseResponse<any>>('/edit/password', {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 邮件通知开关
export async function emailNotification(open: boolean, params?: any) {
  const operate = open ? 'open' : 'close';
  const res = await axios.post<BaseResponse<any>>(`/ins/monitor/email/notify/${operate}`);
  return res.data;
}

// 营销邮件通知开关
export async function marketEmailNotify(open: boolean) {
  const operate = open ? 'open' : 'close';
  const res = await axios.post<BaseResponse<any>>(`/ins/monitor/email/market/notify/${operate}`);
  return res.data;
}

// 获取用户信息
export async function getUserInfo() {
  const res = await axios.get<BaseResponse<UserInfo>>('/user/info');
  return res.data;
}

// 获取用户信息
export async function updateNotifationEmail(receive_email?: string) {
  const res = await axios.post<BaseResponse<any>>(`/user/set/receive_email?receive_email=${receive_email}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}
