import Image from "next/image";
import styled from "styled-components";
import CouponCountdownModal from "./CouponCountdownModal";
import { useState } from "react";
import { useUserProvider } from "@/context/UserProvider";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // UTC 插件
import timezone from 'dayjs/plugin/timezone'; // 时区插件

dayjs.extend(utc);
dayjs.extend(timezone);

const ContentDiv = styled.div`
  position: relative;
`;

const CouponButtonStyled = styled(Image)`
  position: fixed;
  bottom: 100px;
  right: 0;
  /* z-index: 9; */
  cursor: pointer;
`;

const CouponButton = () => {
    const [showModal, setShowModal] = useState(false);
    const { isLogin, coupon } = useUserProvider();

    return (
        <>{isLogin && coupon?.has_coupon && <>
            <CouponButtonStyled
                src={"/images/home/top-coupon-icon.webp"}
                alt=""
                width={64}
                height={48}
                onClick={() => setShowModal(true)}
            />

            {/* 条件渲染模态窗口 */}
            {showModal && (
                <CouponCountdownModal
                    time={dayjs.utc(coupon?.expire_time).tz(dayjs.tz.guess()).diff(dayjs(), 'millisecond')}
                    open={showModal}
                    closeAction={(isOk) => setShowModal(false)} />
            )}
        </>}</>
    );
};

export default CouponButton;