// Import the functions you need from the SDKs you need
import { AppSettings } from '@/shared/app-common';
import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
const isProd =
  process.env.NODE_ENV === 'production' &&
  !process.env.NEXT_APP_API_HOST.includes('www-test.drfans.com');
// const isProd = process.env.NODE_ENV === 'production';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// async function initFirebase() {
//   // Initialize Firebase
//   const apps = getApps();
//   if (!apps.length) {
//     const app = initializeApp(AppSettings.firebaseConfig);

//     if (isProd) {
//       const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));
//     }
//   }
// }

async function initFirebase() {
  // Initialize Firebase
  const apps = getApps();
  if (!apps.length) {
    const app = initializeApp(AppSettings.firebaseConfig);

    if (isProd) {
      const analyticsSupported = await isSupported();
      if (analyticsSupported) {
        getAnalytics(app);
      }
    }
  }
}

initFirebase();
// if (typeof window !== 'undefined') {
//   document.addEventListener('DOMContentLoaded', () => {
//     initFirebase();
//   });
// }
