import axios from 'axios';
import Cookies from 'js-cookie';
import { ls_getItem } from './utils';

const instance = axios.create({
  baseURL: process.env.NEXT_APP_API_HOST,
  timeout: 60000,
  headers: {
    tenantId: `${process.env.NEXT_APP_TENANTID}`,
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('userToken');
    // const token = ls_getItem('userToken');
    if (token != undefined) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
