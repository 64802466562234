
import { App, Button } from 'antd';
import { useRouter } from 'next/router';
import {
    orderSubsQueryRequest,
    orderSubsRequest,
    orderUpgradeRequest,
} from '@/lib/service';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { OrderInfoModel, OrderModel } from '@/types/home';

import { useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import styled from 'styled-components';
import { useUserProvider } from '@/context/UserProvider';

const ContentDiv = styled(Button)`
    display: block;
    width: 100%;
    margin-top: 25px;
    background: linear-gradient( 180deg, #725CFF 0%, rgba(141,131,255,0.8) 100%), #725CFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: #FFF;
    cursor: pointer;
`

const PaymentButtonDetail = ({ title }: { title: string }) => {
    const router = useRouter();
    const { t } = useTranslation('common');
    const { message } = App.useApp();
    const [orderModel, setOrderModel] = useState<OrderModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const { isLogin, onOpenLoginModal, coupon } = useUserProvider();

    const stripe = useStripe();
    const elements = useElements();

    const loadSubList = () => {
        if (!isLogin) {
            onOpenLoginModal(true);
            return
        }
        setLoading(true);
        orderSubsQueryRequest(null)
            .then((res) => {
                if (res.code === 0) {
                    const tempList = res?.data ?? [];
                    let item = tempList.filter((item: OrderInfoModel) => item?.item_id == 7);
                    if (item?.length > 0) {
                        orderSubscriteRequest(item[0].item_id)
                    }
                } else {
                    message.error(res.message);
                }
            })
            .catch((error) => {
                message.error(error.message);
            })
            .finally(() => setLoading(false));
    };

    const orderSubscriteRequest = async (temp_item_id: number) => {
        if (!stripe || !elements || loading) {
            return;
        }
        setLoading(true);

        try {
            const res = await orderUpgradeRequest(coupon?.subs_id, temp_item_id, coupon?.monitor_id, router.locale);

            if (res.code === 0) {
                setOrderModel(res.data);
            } else {
                message.error(res.message);
            }
            // setLoading(false);
        } catch (error: any) {
            message.error(error?.message);
        } finally {
            setLoading(false);
        }
    };

    const loadScripePay = async () => {
        setLoading(true)
        const _ = await stripe?.redirectToCheckout({
            sessionId: orderModel?.client_secret,
        });
        setLoading(false)
    };

    useEffect(() => {
        if (orderModel?.order_id != undefined && orderModel?.client_secret != undefined) {
            loadScripePay();
        }
    }, [orderModel]);

    return <ContentDiv
        loading={loading}
        onClick={() => loadSubList()}>
        {title}
    </ContentDiv>;
};

const PaymentButton = ({ title }: { title: string }) => {
    const isProd =
        process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

    const stripePromise = loadStripe(
        isProd
            ? 'pk_live_51OMmInJag429183DdXXBoQJsqlMRBWbMqtkq7jy3wE9kuOnGm18Lsd3WCVhuYjdgHQUdwUi3CpOXVKPHfjaGyFS1002bEL5ju3'
            : 'pk_test_51OMmInJag429183Dr3UHmAbPd6nwJp5MrFYAw7W7pM1XUCLDjNjPNPgaoGWvDePKRizjFElqUpmopnwKjJh5XbiC001CKFtPMu',
    );

    return (
        <Elements stripe={stripePromise}>
            <PaymentButtonDetail title={title} />
        </Elements>
    );
};

export default PaymentButton;
