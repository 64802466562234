import { useState, useEffect } from "react";
import styled from "styled-components";

const ContentDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const TimeSpan = styled.span<{
    bgColor?: string
}>`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
    color: #725CFF;
    background: ${props => props.bgColor || "#F5F3FF"};
    font-weight: 700;
    display: inline-flex;
    align-items: center;    
    justify-content: center;
`

const SpaceSpan = styled.span`
    padding: 0 5px;
    border-radius: 6px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    display: inline-block;
`

interface CountdownProps {
    initialTime: number; // 以毫秒为单位的初始倒计时时间
    itemBgColor?: string;
    onFinish?: () => void;
}

const CouponCountdownTimer: React.FC<CountdownProps> = ({ initialTime, itemBgColor = '#E3E6F3', onFinish }) => {
    const [remainingTime, setRemainingTime] = useState(initialTime);

    useEffect(() => {
        setRemainingTime(initialTime);
    }, [initialTime])

    // useEffect(() => {
    //     // 每隔10毫秒更新一次倒计时
    //     const intervalId = setInterval(() => {
    //         if (remainingTime <= 10) {
    //             if (onFinish) {
    //                 onFinish()
    //             }
    //         }
    //         setRemainingTime((prevTime) => (prevTime >= 10 ? prevTime - 10 : 0));
    //     }, 10);

    //     // 清除定时器以防止内存泄漏
    //     return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        const start = Date.now();
        const endTime = start + remainingTime;

        const intervalId = setInterval(() => {
            const now = Date.now();
            const timeLeft = Math.max(endTime - now, 0);
            setRemainingTime(timeLeft);

            if (timeLeft <= 0) {
                clearInterval(intervalId);
                if (onFinish) {
                    onFinish();
                }
            }
        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    // 将毫秒转化为小时、分钟、秒和毫秒
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    const milliseconds = Math.floor((remainingTime % 1000) / 10);

    return (
        <ContentDiv>
            <TimeSpan bgColor={itemBgColor}>{hours.toString().padStart(2, '0')}</TimeSpan>
            <SpaceSpan>:</SpaceSpan>

            <TimeSpan bgColor={itemBgColor}>{minutes.toString().padStart(2, '0')}</TimeSpan>
            <SpaceSpan>:</SpaceSpan>

            <TimeSpan bgColor={itemBgColor}>{seconds.toString().padStart(2, '0')}</TimeSpan>
            <SpaceSpan>:</SpaceSpan>

            <TimeSpan bgColor={itemBgColor}>{milliseconds.toString().padStart(2, '0')}</TimeSpan>
        </ContentDiv>
    );
};

export default CouponCountdownTimer;