import { Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { CloseOutlined } from '@ant-design/icons';
import Image from 'next/image';
import CouponCountdownTimer from './CouponCountdownTimer';
import { useUserProvider } from '@/context/UserProvider';
import PaymentButton from './PaymentButton';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';

const ContentDiv = styled.div`
    padding: 20px;
    background: linear-gradient( 180deg, #EEF0FF 0%, #FFFFFF 100%);
    box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.15);
    border-radius: 12px 12px 12px 12px;

    .topContainer {
        text-align: center;

        > .title {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .bottomContainer {

        .timeContainer {
            margin-top: 12px;
            text-align: center;
        }

        .decsContainer {
            margin-top: 20px;
            padding: 20px 24px;
            background: #E3E6F3;
            border-radius: 12px;

            .title {
                font-family: Inter, Inter;
                font-weight: 700;
                font-size: 14px;
                color: #0C0C0C;
                margin-bottom: 15px;
            }

            > .item {
                display: flex;
                margin-bottom: 5px;
            }
        }

        .updateNow {
            margin-top: 25px;
            background: linear-gradient( 180deg, #725CFF 0%, rgba(141,131,255,0.8) 100%), #725CFF;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            color: #FFF;
        }
    }
`

const CouponCountdownModal = ({
    time = 0,
    open = false,
    closeAction,
}: {
    time: number;
    open: boolean;
    closeAction?: (isOk: boolean, e?: any) => void;
}) => {
    const { t } = useTranslation();
    const { onRefreshCouponData } = useUserProvider()
    const { width } = useScreenSize()

    return (

        <Modal
            // title=
            width={380}
            closeIcon={<CloseOutlined style={{ color: '#000' }} />}
            open={open}
            onCancel={(e) => {
                if (closeAction) {
                    closeAction(false, e);
                }
            }}
            footer={null}
            destroyOnClose={true}
            style={{ top: width > 900 ? '100px' : '6%' }}

        >
            <ContentDiv>
                <div className='topContainer'>
                    <Image src={'/images/home/coupon-icon.webp'} alt="" width={108} height={126} />
                    <div className='title' dangerouslySetInnerHTML={{ __html: t(`<p>Flash Sale!<br><span style='color:#725CFF;font-weight:600'>20% OFF</span> Unlimited Annual Plan</p>`) }} />

                </div>

                <div className='bottomContainer'>
                    <div className='timeContainer'>
                        <CouponCountdownTimer
                            initialTime={time}
                            onFinish={() => {
                                if (closeAction) {
                                    closeAction(false);
                                }
                                onRefreshCouponData(null)
                            }}
                        />
                    </div>
                    <div className="decsContainer">
                        <div className='title'>{t("Upgrade To Annual For More Benefits")}</div>

                        {["<p>9 AI Insights Modules</p>",
                            "<p>Additional Annual Activity Reports</p>",
                            "<p>Discover Suspicious Accounts on <span style='color:#725CFF;font-weight:600'>5 Platforms</span></p>",
                            "<p>And more...</p>"
                        ].map((item, index) => {
                            return <div className='item' key={index}>
                                <Image
                                    src={'/images/home/coupon-selected-icon.webp'}
                                    alt={''}
                                    width={16}
                                    height={16}
                                    style={{ marginTop: '4px', marginRight: '5px' }}
                                />
                                <p dangerouslySetInnerHTML={{ __html: t(item) }} />
                            </div>
                        })}
                    </div>
                    {/* <div className="updateNow">
                        {t("🚀 Upgrade Now with Discount！").toLocaleUpperCase()}
                    </div> */}
                    <PaymentButton title={t("🚀 Upgrade Now with Discount!").toLocaleUpperCase()} />

                </div>

            </ContentDiv>
        </Modal>

    );
};

export default CouponCountdownModal;
